<li class="order-item" (click)="selectItem()">
    <div class="row">
        <div class="col">
            {{item.name}}
        </div>
        <div class="col">
            {{item.created | date:'dd.MM.yyyy'}}
        </div>
        <div class="col">
            {{item.platformOrderId}}
        </div>
        <div class="col">
            {{item.loginName}} <span *ngIf="userService.allow('GLOBAL-ADMIN')"> (ID: {{item.loginId}})</span>
        </div>
        <div class="col">
            <div *ngFor="let supplier of item?.suppliers">
                <img class="img-fluid"
                     [supplier]="supplier"
                     [logo-color]="true">
            </div>
        </div>
        <div class="col">
            <div *ngFor="let supplier of item?.suppliers">
                {{item.externalIds[supplier]}}
            </div>
        </div>
    </div>
</li>
