import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {OrderHistoryService} from '../services/order-history.service';
import {GlobalAdminService} from '../../../global/services/global-admin.service';

interface orderItem {
    clubName: string,
    created: Date,
    externalIds: string,
    firmName: string,
    id: string,
    loginId: number,
    loginName: string,
    name: string,
    reference?: string,
    platformOrderId: string,
    suppliers: string[]
}

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
    @Input() public item: orderItem;
    @Input() public orderList?: any;

    constructor(public userService: UserService,
                public globalAdminService: GlobalAdminService,
                public helperService: HelperService,
                public orderHistoryService: OrderHistoryService) {
    }

    protected selectItem() {
        this.orderHistoryService.setCart(this.item);
    }
}
