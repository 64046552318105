<div class="heading blue" *ngIf="location.path().startsWith('/company-admin/orderhistory')">
    <span class="clickable" (click)="orderHistoryService.reset()" routerLink="/company-admin/orderhistory">
      <i class="fa fa-th"></i>
      <span>{{'CART.ORDER_HISTORY' | translate}}</span>
    </span>
    <span class="clickable" (click)="orderHistoryService.setHistory(orderHistoryService?.selectedHistory)" routerLink="/company-admin/orderhistory" *ngIf="orderHistoryService?.selectedHistory !== 'orderhistory'">
      <i class="fa fa-caret-right"></i>
      <span>{{'CART.HISTORY.' + orderHistoryService?.selectedHistory?.toUpperCase() | translate}}</span>
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>{{orderHistoryService?.selectedCart?.name}} ({{ orderHistoryService?.selectedCart?.created | date:'dd.MM.yyyy' }})</span>
    </span>
</div>
<div class="panel-content center-content print-content" [ngClass]="{'setting' : !showPdf}">
    <app-default-loading-page *ngIf="(!cartService.currentCart || cartService.cartLoading) && location.path().endsWith('/offer')"></app-default-loading-page>
    <div class="print-settings" [ngClass]="{'move-up': showPdf, 'move-down': !showPdf}" >
        <div class="row">

            <div class="col-md-4 col-lg-4">

                <div class="box-title">{{ 'PRINT_VIEW.CUSTOMER_DATA' | translate }}</div>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.NAME' | translate"
                                         [text]="getCustomerFirstName()" (textChange)="setCustomerFirstName($event)"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.NAME2' | translate"
                                         [text]="getCustomerLastName()" (textChange)="setCustomerLastName($event)"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.STREET_AND_NO' | translate"
                                         [text]="getCustomerAddress()" (textChange)="setCustomerAddress($event)"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.ZIP_CODE' | translate"
                                         [text]="getCustomerZip()" (textChange)="setCustomerZip($event)"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.CITY' | translate"
                                         [text]="getCustomerCity()" (textChange)="setCustomerCity($event)"></app-material-form-input>

            </div>

            <div class="col-md-4 col-lg-4 article-number">

                <div class="box-title">{{ 'PRINT_VIEW.CAR_DATA' | translate }}</div>


                <app-material-form-input [label]="'PRINT_VIEW.FORM.NUMBER_PLATE' | translate"
                                         [text]="getNumberplate()" (textChange)="setNumberplate($event)"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.CAR_NAME' | translate"
                                         [(text)]="pdfService.client.carName"></app-material-form-input>

                <app-checkbox [labelText]="'PRINT_VIEW.HIDE_ARTICLE_NUMBER' | translate" [isChecked]="hideArticleNumbers" (onChange)="hideArticleNumbers = $event"></app-checkbox>

            </div>

            <div class="col-md-4 col-lg-4">
                <div class="box-title">{{ 'PRINT_VIEW.ADD_INFO' | translate }}</div>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.OFFER_NO' | translate"
                                         [(text)]="pdfService.client.offerNo"></app-material-form-input>

                <app-material-form-input [label]="'PRINT_VIEW.FORM.REFERENCE' | translate"
                                         [(text)]="pdfService.client.reference"></app-material-form-input>

            </div>
            <div class="col-12 text-left">

                <button class="base-btn success " (click)="createPdf()" *ngIf="!showPdf && !cartService.cartLoading && !cartService.isOrder()" >
                    <i class="fa fa-file-pdf-o"></i> {{ 'PRINT_VIEW.TITLE' | translate }}
                </button>

                <button class="base-btn success" (click)="showPdf = !showPdf" *ngIf="showPdf && !cartService.isOrder()">
                    <i class="fa fa-edit"></i> {{ 'PRINT_VIEW.CUSTOMER_DATA' | translate }}
                </button>

                <button class="base-btn " (click)="printPdf()" *ngIf="showPdf && !helperService.browserIsIE() && !helperService.browserIsEdge()">
                    <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
                </button>

                <button class="base-btn " (click)="downloadPdf()" *ngIf="showPdf">
                    <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
                </button>

                <button class="base-btn pull-right button-top-margin" routerLink="{{cartService.backLink}}" >
                    <i class="fa fa-times"></i> {{ 'PROMPTS.BUTTONS.GO_BACK' | translate }}
                </button>
            </div>

        </div>
    </div>

    <div class="print-preview" *ngIf="showPdf">
        <app-default-loading-page *ngIf="pdfLoading"></app-default-loading-page>
        <pdf-viewer
                [src]="pdf"
                [autoresize]="false"
                [render-text]="false"
                [show-all]="true"
                style="display: block;"
        ></pdf-viewer>
    </div>

    <app-profit-check *ngIf="!showPdf"></app-profit-check>

    <app-sellout-check *ngIf="!showPdf"></app-sellout-check>
</div>
