<div class="global-order-details">
  <div class="row">
    <div class="col">
      <button class="base-btn float-right" (click)="orderHistoryService.cartData = globalAdminService?.orderDetails" *ngIf="!userService.sale" routerLink="orderhistory/order-print">
        <i class="fa fa-print"></i>
        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
      </button>
      <button class="base-btn float-right" *ngIf="userService.sale" (click)="pdfService.updateDmsData(); cartService.currentCart = globalAdminService?.orderDetails; cartService.backLink = router.url"
              routerLink="orderhistory/offer">
        <i class="fa fa-file-pdf-o"></i>
        <span>{{'PRINT_VIEW.PRINT_OFFER' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="row padding-top" *ngIf="externalIdsNotEmpty()">
    <div class="col">
      <app-collapsible-panel [panelTitle]="supplier.value.supplierName" *ngFor="let supplier of globalAdminService?.orderDetails?.supplierInformation | cat">
        <div class="row">
          <div class="col-5">
            <span>Bestellnummer</span>
          </div>
          <div class="col-7">
            <span>{{supplier.value?.externalId}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <span>{{'CART.ACQUISITION_TYPE' | translate}}</span>
          </div>
          <div class="col-7">
            <span>{{'CART.ACQUISITION_TYPES.' + globalAdminService?.orderDetails.supplierInformation[supplier.key].acquisitionInformation.selectedAcquisitionType?.acquisitionType | translate}}</span>
            <span *ngIf="globalAdminService?.orderDetails.supplierInformation[supplier.key].acquisitionInformation.selectedAcquisitionType?.label?.length >0" > - {{globalAdminService?.orderDetails.supplierInformation[supplier.key].acquisitionInformation.selectedAcquisitionType?.label}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="base-btn" (click)="globalAdminService.getOrderTransferLog(supplier.key, supplier.value.supplierName)">Transfer Log</button>
          </div>
        </div>
      </app-collapsible-panel>
    </div>
  </div>
  <div class="row padding-top" *ngIf="externalIdsEmpty()">
    <div class="col">
      <app-collapsible-panel [panelTitle]="supplier.value.supplierName" *ngFor="let supplier of globalAdminService?.orderDetails?.supplierInformation | cat">
        <div class="row">
          <div class="col-5">
            <span>{{'CART.ACQUISITION_TYPE' | translate}}</span>
          </div>
          <div class="col-7">
            <span>{{globalAdminService?.orderDetails.supplierInformation[supplier.key].acquisitionInformation.selectedAcquisitionType.acquisitionType}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button class="base-btn" (click)="globalAdminService.getOrderTransferLog(supplier.key, supplier.value.supplierName)">Transfer Log</button>
          </div>
        </div>
      </app-collapsible-panel>
    </div>
  </div>
  <ng-container *ngIf="globalAdminService?.orderDetails?.mails?.length > 0">
    <div class="row padding-top">
      <div class="col">
        <app-collapsible-panel [panelTitle]="'Mail - ' + mail.subject" *ngFor="let mail of globalAdminService?.orderDetails?.mails">
          <div class="row">
            <div class="col-5">
              <span>Empfänger</span>
            </div>
            <div class="col-7">
              <span>{{mail.to}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
              <span>Status</span>
            </div>
            <div class="col-7">
              <span *ngIf="mail.sent" class="font-color-green">Gesendet, {{mail.sent | date}}</span>
              <span *ngIf="!mail.sent" class="font-color-red">Noch nicht gesendet</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="base-btn" (click)="openMail(mail)">Öffnen</button>
            </div>
          </div>
        </app-collapsible-panel>
      </div>
    </div>
  </ng-container>

</div>
