import {
    Component,
    OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {OrderHistoryService} from './services/order-history.service';
import {TranslateService} from '@ngx-translate/core';
import {workListAnim} from '../../../shared/animations/general.animation';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-order',
    templateUrl: './order-history.html',
    styleUrls: ['./order-history.component.scss'],
    animations: [workListAnim]
})
export class OrderHistoryComponent implements OnInit {

    constructor(public router: Router, public orderHistoryService: OrderHistoryService, public translate: TranslateService, public userService: UserService, public helperService: HelperService) {
        this.orderHistoryService.selectedCart = undefined;
    }

    ngOnInit() {
        if (!this.orderHistoryService.selectedHistory) {
            this.orderHistoryService.setHistory(this.router.url.substring(this.router.url.lastIndexOf('/') + 1));
        }
        if (this.orderHistoryService.selectedHistory) {
            this.orderHistoryService.setHistory(this.orderHistoryService.selectedHistory);
        }
    }

    public onEnd() {
        if (this.orderHistoryService.orderHistoryRequest && this.orderHistoryService.orderHistoryRequest.totalElements > 0) {
            return this.userService.enableInfiniteScroll && (this.orderHistoryService.orderHistoryRequest.totalElements / 20) <= (this.orderHistoryService.orderHistoryRequest.page + 1) && !this.orderHistoryService.infinityLoading;
        } else {
            return false;
        }
    }

}
