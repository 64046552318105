import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HelperService} from "../services/helper.service";

@Directive({
    selector: '[supplier]'
})
export class SupplierLogoDirective implements OnInit, OnChanges {
    @Input('supplier') supplier: any;
    @Input('logo-color') color: boolean;
    private nativeElement: HTMLElement;

    constructor(private http: HttpClient,
                private el: ElementRef,
                private helperService: HelperService,
                private renderer: Renderer2) {
        this.nativeElement = el.nativeElement;
        const me = this;

        // if the image could not be loaded, it renders a fallback image
        this.nativeElement.addEventListener("error", function(error) {
            me.renderer.setAttribute(me.nativeElement, 'src', '/assets/images/supplier/haendler_fallback.png');
        });
    }

    ngOnInit() {
        this.loadImage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.supplier || changes.color) {
            this.loadImage();
        }
    }

    private loadImage(): void {
        if (this.supplier) {
            let supp = this.supplier
            if (supp.supplier) {
                supp = supp.supplier
            }
            if (this.color) {
                this.renderer.setAttribute(this.nativeElement, 'src', this.getSupplierLogoUrl(supp, 'ACTIVE'));
            } else {
                this.renderer.setAttribute(this.nativeElement, 'src', this.getSupplierLogoUrl(supp, 'INACTIVE'));
            }
        }
    }

    public getSupplierLogoUrl(supplierQualifier: string, stat: string): string {
        return `${environment.supplierLogo}/logo/${supplierQualifier}/${stat}`
    }
}
