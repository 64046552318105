<div class="order-history global-orderhistory">
    <div class="heading blue">
        <span class="clickable" routerLink="/company-admin">
          <i class="fa fa-th" aria-hidden="true"></i>
            {{ 'ADMIN_PANEL.OVERVIEW' | translate }}
        </span>
        <span class="clickable" (click)="orderHistoryService.reset()" routerLink="/company-admin/orderhistory">
          <i class="fa fa-caret-right"></i>
          <span>{{ 'CART.ORDER_HISTORY' | translate }}</span>
        </span>
        <span class="clickable" routerLink="/company-admin/orderhistory"
              *ngIf="orderHistoryService?.selectedHistory && orderHistoryService?.selectedHistory !== 'orderhistory'">
          <i class="fa fa-caret-right"></i>
          <span>{{ 'CART.HISTORY.' + orderHistoryService?.selectedHistory.toUpperCase() | translate }}</span>
        </span>
    </div>
    <div class="panel-content center-content">
        <div class="assembly-categories" *ngIf="orderHistoryService?.selectedHistory === 'orderhistory'">
            <div class="assembly-category" (click)="orderHistoryService?.setHistory('last7')">
                <div class="name-wrapper-no-image">
                    <span>{{ 'CART.HISTORY.LAST7' | translate }}</span>
                </div>
            </div>
            <div class="assembly-category" (click)="orderHistoryService?.setHistory('last14')">
                <div class="name-wrapper-no-image">
                    <span>{{ 'CART.HISTORY.LAST14' | translate }}</span>
                </div>
            </div>
            <div class="assembly-category" (click)="orderHistoryService?.setHistory('last30')">
                <div class="name-wrapper-no-image">
                    <span>{{ 'CART.HISTORY.LAST30' | translate }}</span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="orderHistoryService?.selectedHistory && orderHistoryService?.selectedHistory !== 'orderhistory'">
            <div class="orderHistoryHeader">
                <h1>{{ 'CART.HISTORY.' + orderHistoryService?.selectedHistory.toUpperCase() | translate }}</h1>
            </div>
        </ng-container>
    </div>
    <div class="center-content"
         [ngClass]="{'hasResults': orderHistoryService?.orderHistoryRequest?.totalElements > 0, 'showPagination': !userService?.enableInfiniteScroll }"
         appInfiniteScroller
         (onScrollEnd)="orderHistoryService?.loadNext($event)">
        <app-default-loading-page *ngIf="orderHistoryService.loadingPlacedOrders && !orderHistoryService.infinityLoading"></app-default-loading-page>
        <div class="row">
            <div class="col">
                <ul class="item-list" *ngIf="orderHistoryService.orderHistoryRequest?.orders"
                    [@workListAnim]="(orderHistoryService.orderHistoryRequest ? orderHistoryService.orderHistoryRequest.totalElements : 0)">
                    <li *ngIf="orderHistoryService.orderHistoryRequest.orders.length > 0">
                        <div class="row font-weight-bold">
                            <div class="col">
                                {{ 'CART.REFERENCE' | translate }}
                            </div>
                            <div class="col">
                                {{ 'GENERAL.DATE' | translate }}
                            </div>
                            <div class="col">
                                {{ 'ORDER.ORDERNUMBER' | translate }}
                            </div>
                            <div class="col">
                                {{ 'SUPPLIERS.USERNAME' | translate }}
                            </div>
                            <div class="col">
                                {{ 'ORDER.SUPPLIER' | translate }}
                            </div>
                            <div class="col">
                                {{ 'ORDER.SUPPLIER_EXTERNAL_ID' | translate }}
                            </div>
                        </div>
                    </li>
                    <ng-container *ngFor="let item of orderHistoryService.orderHistoryRequest.orders">
                        <app-order-item [item]="item" class="global-order-row" [orderList]="orderHistoryService.orderHistoryRequest.orders"></app-order-item>
                    </ng-container>
                </ul>
                <div class="noHover row app-articles-row" *ngIf="orderHistoryService.infinityLoading">
                    <i class="centerItem fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                </div>
                <div class="noHover row app-articles-row" *ngIf="onEnd()">
                    <span class="centerItem">{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate }}</span>
                </div>
                <app-no-results *ngIf="orderHistoryService.orderHistoryRequest?.totalElements === 0"></app-no-results>
            </div>
        </div>
    </div>
    <div class="global-orderhistory global-pagination" *ngIf="!orderHistoryService.loadingPlacedOrders && !userService.enableInfiniteScroll">
        <app-pagination [page]="orderHistoryService?.orderHistoryRequest?.page + 1"
                        [perPage]="orderHistoryService?.orderHistoryRequest?.pageSize"
                        [count]="orderHistoryService?.orderHistoryRequest?.totalElements"
                        [pagesToShow]="5"
                        (goPage)="orderHistoryService?.setHistory('', '', $event)">
        </app-pagination>
    </div>
</div>
